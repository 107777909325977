@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

p {
  color: #5B3625;
  font-size: 20px;
  margin: 0;
  line-height: 1.5;
}

h1 {
  color: #CC0000;
  font-size: 25px;
  font-weight: 600;
  margin: 0;
  line-height: 1.5;
}

h2 {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  line-height: 1.5;
}

h3 {
  color: #898989;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  line-height: 1.5;
}

.footer span {
  color: #828282;
  line-height: 1.5;
}

.column_item_icon {
  color: #5F6368;
  font-size: 75px;
  margin-bottom: 30px;
}

@keyframes showsUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.header_card h1 {
  color: #000000;
  line-height: 1.5;
  font-size: 22px;
  font-weight: 600;
  transform: translateY(20px);
  opacity: 0;
  animation: showsUp .6s ease forwards 1.6s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.header_card {
  background: hsl(0deg 0% 98% / 80%);
  padding: 42px 37px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 1s ease forwards 0.4s;
  /* Only runs once */
}

a {
  text-decoration: none;
}

.navbar_links a {
  color: #697386;
  line-height: 1.5;
  font-weight: 500;
  transition: .3s ease all;
  font-size: 14px;
}

.wrapper {
  max-width: calc(1140px + 8%);
  margin: 0 auto;
  padding: 0 4%;
}

p.column_item_paragraph {
  font-size: 15px;
}

.column_3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  row-gap: 60px;
  transition: all .8s ease-out 0.1s;
}

.column_3 .column_item {
  flex: 0 0 calc((100% - 7% * 3) / 3);
}

img.column_item_image {
  display: block;
  width: 100%;
  margin-bottom: 25px;
}

.section_title {
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  opacity: 1;
  transform: translateY(0);
  transition: all .8s ease-out 0.1s;
  position: relative;
}

.not_revealed {
  opacity: 0;
}

.not_revealed.section_title {
  transform: translateY(40px);
}

.section_container {
  padding-top: 80px;
}

.partners_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding-top: 80px;
  transition: all .8s ease-out 0.1s;
}

.partners_list {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.partner_item {
  width: 100%;
  max-width: 200px;
}

.partner_item img {
  display: block;
  width: 100%;
}

.column_info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 127px;
  height: 250px;
  justify-content: center;
}

.footer_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.footer_logo {
  display: flex;
  margin-bottom: 24px;
}

.navbar {
  display: flex;
  height: 61px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.header {
  height: 100%;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4% 0 4%;
  align-items: flex-start;
}

.root {
  height: 100%;
}

.navbar_links {
  display: flex;
  gap: 35px;
}

.header_card p {
  max-width: 431px;
  transform: translateY(20px);
  opacity: 0;
  animation: showsUp .6s ease forwards 2.2s;
}

.navbar_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 4%;
  transition: .3s ease all;
  background: #fff;
  box-shadow: 0 0 5px 0 hsl(0deg 0% 0% / 25%);
  z-index: 3;
}

.navbar_container.solid {
  background: linear-gradient(to bottom, rgb(0 0 0 / 60%), transparent);
  box-shadow: none;
}

.navbar_container.solid .navbar_links a {
  color: #fff;
}

.header_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.language_switcher_main {
  display: flex;
  color: #697386;
  transition: .3s ease all;
  align-items: center;
  gap: 10px;
  line-height: 1.5;
  cursor: pointer;
}

.navbar_right {
  display: flex;
  align-items: center;
}

.language_switcher_main .language_icon {
  font-weight: 300;
  font-size: 21px;
}

.language_switcher {
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid hsl(219deg 12% 47% / 20%);
  transition: .3s ease all;
}

.language_switcher_main span {
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  width: 80px;
}

.language_switcher_main .language_drop_icon {
  font-weight: 300;
}

.navbar_container.solid .language_switcher_main {
  color: #fff;
}

.navbar_container.solid .language_switcher {
  border-color: rgba(255, 255, 255, 0.2);
}

.language_dropdown_list {
  position: fixed;
  right: 4%;
  top: 61px;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 165px;
  padding: 6px;
  box-shadow: 0 0 5px 0 hsl(0deg 0% 0% / 15%);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  transition: .2s ease all;
}

.language_dropdown_list span {
  color: #697386;
  line-height: 1.5;
  font-weight: 500;
  transition: .2s ease all;
  font-size: 14px;
  text-transform: capitalize;
  padding: 6px 20px;
  cursor: pointer;
}

.language_dropdown_list span:hover {
  background: rgb(105 116 134 / 10%);
}

.language_dropdown_list.active {
  pointer-events: initial;
  opacity: 1;
}

.navbar_mobile_menu {
  display: none;
}

.navbar_logo {
  display: flex;
  align-items: center;
}

.navbar_left {
  display: flex;
  gap: 18px;
  align-items: center;
}

.navbar_mobile_list_container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  overflow: auto;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
  pointer-events: none;
}

.material-symbols-outlined {
  user-select: none;
}

.navbar_mobile_background {
  background: transparent;
  width: 100%;
  transition: all .6s ease;
  pointer-events: none;
  position: fixed;
  height: 100%;
  z-index: 2;
}

.navbar_mobile_list {
  top: -359px;
  padding: 79px 16px 30px 16px;
  background: #fff;
  flex-direction: column;
  display: flex;
  width: 100%;
  box-shadow: 0 0 5px 0 hsl(0deg 0% 0% / 15%);
  position: relative;
  transition: all .6s ease;
  pointer-events: none;
  z-index: 4;
  height: 359px;
}

@media only screen and (max-width: 1200px) {

  .language_switcher_main span,
  .language_switcher_main .language_drop_icon {
    display: none;
  }
}

@media only screen and (max-width: 1060px) {
  .navbar_container.solid .navbar_mobile_menu {
    color: #fff;
  }

  .language_switcher {
    border: 0;
    padding: 0;
  }

  .language_dropdown_list {
    width: 150px;
  }

  .language_dropdown_list span {
    padding: 6px 20px 6px 25px;
  }

  .navbar_links {
    display: none;
  }

  .navbar_mobile_menu {
    display: flex;
    color: #697386;
    cursor: pointer;
    font-weight: 300;
  }

  .language_switcher {
    margin-left: 0;
  }

  .language_switcher_main span {
    display: none;
  }

  .language_switcher_main .language_drop_icon {
    display: none;
  }

  .navbar_right {
    margin-left: 18px;
  }

  .navbar_left {
    width: 100%;
  }

  .navbar_logo {
    margin: 0 auto;
  }

  .language_dropdown_list {
    display: none;
  }

  .navbar_mobile_list_container a,
  .navbar_mobile_list_container span {
    color: #697386;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    text-transform: capitalize;
    cursor: pointer;
  }

  .navbar_container {
    padding: 0 32px;
  }

  .navbar_mobile_list_container a:hover,
  .navbar_mobile_list_container span:hover {
    background: #6974861a;
  }

  .navbar_mobile_list_container.active .navbar_mobile_list {
    pointer-events: initial;
    top: 0;
  }

  .navbar_mobile_list_container.active .navbar_mobile_background {
    background: hsl(0deg 0% 0% / 65%);
    pointer-events: initial;
  }

  .navbar_container.solid {
    background: #fff;
    box-shadow: 0 0 5px 0 hsl(0deg 0% 0% / 25%);
  }

  .navbar_container.solid .language_switcher_main {
    color: #697386;
  }

  .navbar_container.solid .navbar_mobile_menu {
    color: #697386;
  }

  .navbar_mobile_list_container.active {
    pointer-events: initial;
  }

}

@media only screen and (max-width: 700px) {

  .wrapper {
    padding: 0;
  }

  .section_title {
    padding: 0 4%;
  }

  .partners_container h3 {
    padding: 0 4%;
  }

  .footer {
    padding: 0 4%;
  }

  .column_3 {
    flex-wrap: nowrap;
    justify-content: inherit;
    padding: 0 4%;
    gap: 30px;
    overflow: auto;
    padding-bottom: 20px;
  }

  .column_3 .column_item {
    flex: 0 0 60%;
  }

  .partners_list {
    flex-wrap: nowrap;
    justify-content: inherit;
    padding: 0 4%;
    overflow: auto;
    padding-bottom: 20px;
  }

  .partner_item {
    flex: 0 0 40%;
    max-width: inherit;
  }

  p {
    font-size: 16px;
  }
  
  h1 {
    font-size: 20px;
  }
  
  h2 {
    font-size: 18px;
  }
  
  h3 {
    font-size: 14px;
  }
  
  .column_item_icon {
    font-size: 60px;
  }
  
  .header_card h1 {
    font-size: 18px;
  }
  
  .navbar_links a {
    font-size: 12px;
  }
  
  p.column_item_paragraph {
    font-size: 13px;
  }
  
  .section_title {
    margin-bottom: 60px;
  }
  
  .section_container {
    padding-top: 40px;
  }
  
  .partners_container {
    padding-top: 40px;
  }
  
  .section_title {
    margin-bottom: 60px;
  }
  
  .footer {
    margin-top: 100px;
  }
  
  .header_card {
    gap: 10px;
  }
  
  .section_title {
    gap: 10px;
  }
  
  .partners_container {
    gap: 25px;
  }
  
  .column_info {
    gap: 10px;
  }
  
  .column_item_icon {
    margin-bottom: 25px;
  }
  
  img.column_item_image {
    margin-bottom: 20px;
  }
  

}